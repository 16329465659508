.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #ededed;
    border-top-color: #06c9ff;
    border-radius: 50%;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }